@import 'colors.scss';

.accordion {
    .accordion-header {
        .show {
            display: block;
        }

        .hide {
            display: none;
        }

        &[aria-expanded="true"] {
            .show {
                display: none;
            }

            .hide {
                display: block;
            }
        }
    }
}
