.sidebar {
    &.dg-sidebar {
        &.visible {
            @media screen and (max-width: $breakpoint-lg) {
                display: block;
                position: absolute;
                margin-top: 80px;
            }
        }
    }

    @media screen and (max-width: $breakpoint-lg) {
        display: none;
    }

    &.topbar-open {
        display: block;
    }

    .sidebar-main-nav {
        @media screen and (max-width: $breakpoint-lg) {
            border-top: #ffffff;

            &:before {
                background-color: #ffffff;
            }
        }

        .sidebar-logo {
            @media screen and (max-width: $breakpoint-lg) {
                display: none;
            }
        }

        .main-nav-ul {
            margin-top: 0;
            height: calc(100vh - 120px);
            padding: 0 1rem;
        }
    }
}

#dg-sidebar {
    &.sidebar {
        .nav-ul {
            list-style: none;
            overflow: hidden;
            padding: 0;
            top: auto;
            position: relative;
            transition: none;
        }
    }

    .main-nav-ul {
        a {
            &.disabled {
                ~nav {
                    display: none;
                }
            }
        }

        .leaf {
            &.active {
                >a {
                    &:hover {
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
}
