@import 'colors.scss';
@import 'fonts.scss';
@import 'breakpoints.scss';

$front-page-padding: 0 4rem;
$front-page-max-width: $breakpoint-lg;

@mixin row-cards {
    >[class*='col-'] {
        display: flex;
    }
}

@mixin front-page-cards ($color) {
    margin-bottom: 4rem;

    .row {
        @include row-cards();

        &>:first-child {
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: -0.5rem;
                width: 3.5rem;
                height: 2.5rem;
                background-color: $color;
                background-image: radial-gradient($color 20%, $background-front-page 20%);
                background-position: 0 0;
                background-size: 0.45rem 0.5rem;
            }
        }

        &>:last-child {
            &:before {
                content: "";
                position: absolute;
                top: 1rem;
                right: -0.25rem;
                width: 3.5rem;
                height: 2.5rem;
                background-color: $color;
                background-image: radial-gradient($color 20%, $background-front-page 20%);
                background-position: 0 0;
                background-size: 0.45rem 0.5rem;
            }
        }
    }
}

.front-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    #github {
        top: 2.5rem;

        path {
            fill: $black;
        }
    }

    .front-page-top {
        position: relative;
        margin: 0 4rem;
        max-width: $front-page-max-width;
        background-color: none;
        z-index: 0;
        border-radius: 1rem;

        @media screen and (max-width: $breakpoint-md) {
            margin: 0;
        }

        .front-page-hero {
            position: relative;
            margin-bottom: 1.5rem;
            z-index: 1;
            padding: 1.5rem;

            @media screen and (max-width: $breakpoint-md) {
                padding: 0;
            }

            h3 {
                color: $brown;
                font-family: $font_headline;
                margin-bottom: 2rem;
                font-size: 2.5rem;
                line-height: 3.5rem;

                @media screen and (max-width: $breakpoint-md) {
                    font-size: 1.25rem;
                    line-height: 2.25rem;                    
                    margin-bottom: 1rem;
                }
                    
            }

            p {
                color: $brown;
                font-size: 1.125rem;
            }

            a {
                color: #43d0dd;
            }

            .front-page-hero-jumbo {
                color: $brown;
                font-family: $font_headline;
                margin-bottom: 2rem;
                font-size: 3.5rem;

                @media screen and (max-width: $breakpoint-md) {
                    font-size: 2.25rem;
                }
            }

            .front-page-hero-lean {
                font-family: $font_mono;
                white-space: nowrap;

                &::before {
                    content: ' {';
                    font-family: $font_mono;
                    font-weight: bold;
                    color: $yellow;
                }

                &::after {
                    content: '} ';
                    font-family: $font_mono;
                    font-weight: bold;
                    color: $yellow;
                }
            }


        }

        .front-page-intro-cards {
            h2 {
                color: $brown;
                padding: 1.5rem;
                padding-bottom: 0;

                .header-anchor {
                    display: none;
                }
            }

            .row {
                @include row-cards();
            }
        }

        @media screen and (min-width: $breakpoint-xxl) {

            .front-page-intro-cards {
                left: 0;
                width: 100%;
            }
        }
    }

    .front-page-container {
        max-width: $front-page-max-width;
        margin: $front-page-padding;
        position: relative;

        @media screen and (max-width: $breakpoint-md) {
            margin: 0;
        }

        .front-page-merchants {
            margin-bottom: 3.75rem;

            .merchants-container {
                display: flex;
                flex-wrap: wrap;

                .merchant-link {
                    display: flex;
                    align-items: center;
                    height: 2rem;
                    margin: 0.25rem 2rem 1rem 0;
                }
            }
        }


        .front-page-cards-sdk {
            @include front-page-cards($turquoise);
        }

        .front-page-cards-module {
            @include front-page-cards($card-pink);
        }


        .front-page-release-notes {
            margin-bottom: 4rem;

            .release-notes-container {
                margin-top: 2.5rem;

                .row {
                    border-bottom: 1px solid $background-gray;
                    margin-bottom: 2rem;
                    padding-bottom: 1rem;

                    .release-notes-date {
                        color: $medium-brown;
                    }

                }
            }
        }

        .front-page-cards-extra {
            @include front-page-cards($yellow);
        }

        .front-page-contact {
            display: none; // Not currently in use
            // display: flex;

            .front-page-contact-content {
                background-color: $dark-brown;
                width: 100%;
                min-height: 13.5rem;
                padding: 3.5rem 4rem;

                h2 {
                    color: $white;
                    margin-bottom: 1.5rem;


                    .header-anchor {
                        display: none;
                    }
                }

                p {
                    color: $white;
                }

                .btn {
                    width: 18rem;
                    justify-content: center;
                    margin: 1rem 0;

                    &:focus,
                    &:focus,
                    &:hover {
                        color: $medium-brown;
                    }
                }
            }

        }
    }
}