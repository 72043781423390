$yellow: #fdc129;
$soft-yellow: #fff3d5;
$light-turquoise: #ebf8f2;
$apricot: #fbf2ea;
$pink: #efb7b6;
$brand-info: #4572c0;
$brand-info-light: #e8eff9;
$brand-success: #51971b;
$brand-success-light: #f2f7eb;
$turquoise: #31a3ae;
$soft-turquoise: #98d0d6;
$turquoise-link: #257886;
$brown: #2f2424;
$dark-brown: #493c3b;
$medium-brown: #72605e;
$soft-brown: #a38b80;
$white: #fff;
$card-pink: #c5569a;
$soft-card-pink: #d698c8;
$background-gray: #ebE7E2;
$background-front-page: #fefcfa;
$code-green: #9c6;
$black: #222;
