@import 'colors.scss';

.title-header {
    display: flex;
    justify-content: center;
    background-color: $dark-brown;

    .title-header-container {
        width: 100%;
        padding: 3rem 1rem;

        h1,
        h4,
        p {
            color: $white;
        }

        h1 {
            margin-top: 0;
        }

        p {
            font-size: 1.125rem;
        }

        .title-header-estimated-read {
            color: $yellow;
            display: flex;
            margin-bottom: 0.75rem;

            i {
                margin-right: 0.5rem;
            }
        }
        a {
            color: #43d0dd;

            ::after {
                content: url(../img/external-url-dark-bg.svg);
            }
        }
    }



}

.title-header-checkout-v3 {
    display: flex;
    justify-content: center;

    #github {
        path {
            fill: $black;
        }
    }

    .title-header-container {
        width: 100%;
        padding: 3rem 1rem;
        padding-bottom: 0;

        h1,
        h4,
        p {
            color: $brown;

            strong {
                font-size: 1.25rem;
            }
        }

        h1 {
            margin-top: 0;
            color: $brown;
        }

        h4 {
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.125rem;
        }

        .title-header-estimated-read {
            color: $yellow;
            display: flex;
            margin-bottom: 0.75rem;

            i {
                margin-right: 0.5rem;
            }
        }
        a {
            color: #43d0dd;

            ::after {
                content: url(../img/external-url-dark-bg.svg);
            }
        }


    }



}
